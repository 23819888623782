<template>
  <el-form-item :label="label" prop="picUrl" :required="required">
  <el-upload :action="uploadUrl" :show-file-list="false" :accept="'image/*'"  :on-success="handleSuccess" style="float: left;height: 40px">
    <el-image style="width: 100px; height: 40px" :src="picUrl" v-show="picUrl" fit="fill" @click.stop="showPic"></el-image>
    <el-button type="primary" v-show="!picUrl">上传 <i class="el-icon-upload el-icon-right"></i></el-button>
    <el-button type="primary" v-show="picUrl" style="position: relative; top:-10px;left:20px" >上传 <i class="el-icon-upload el-icon-right"></i></el-button>
  </el-upload>
  <span style="line-height: 38px;color:#666;   position: relative;top:0px;left:50px;" v-show="!picUrl">{{size}}</span>
  <span style="line-height: 38px;color:#666;   position: relative;top:5px;left:50px;" v-show="picUrl">{{ size }}</span>
  </el-form-item>
</template>

<script>
import {UrlEnum} from "../../public/js/common-vue";

export default {
  name: "UploadFormItem",
  data(){
    return{
      uploadUrl:UrlEnum.UPLOAD_PIC,
      picUrl:'',
      label:'',
      required:false,
      size:''
    }
  },
  methods:{
    showPic(){
      window.open(this.picUrl);
    },
    handleSuccess(response, file, fileList) {
      console.log(response);
      this.picUrl = response.data.src;
      this.$emit('parentEvent',this.picUrl)
    },
    handleError() {
      this.showMsgError("上传失败,请重新上传图片!");
    },
    handleBeforeUpload(file) {
      const isImage = file.type.includes("image");
      if (!isImage) {
        this.showMsgError("上传文件类型必须是图片!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.showMsgError("上传图片大小不能超过 2MB!");
      }
      return isImage && isLt2M;
    },
    handleProgress(event, file, fileList) {
      // this.loading = true;  //  上传时执行loading事件
    },
    init(label,required,size,picUrl){
      this.label = label;
      this.required = required;
      this.size = size;
      this.picUrl = picUrl;
    },
    setSize(size){
      this.size = size;
    }
  }
}
</script>

<style scoped>

</style>
