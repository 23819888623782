<template>
  <div id="app-teacher-save" class="app-add">
    <loading :loading="loading" :text="loadingText"></loading>
    <el-form ref="form" :model="teacher"  label-width="100px">
      <el-form-item label="姓名" :required="true">
        <el-input type="text"  v-model="teacher.name" placeholder="请输入" :required="true"></el-input>
      </el-form-item>
      <el-form-item label="手机号" :required="true">
        <el-input type="text"  v-model="teacher.username" placeholder="请输入" :required="true"></el-input>
      </el-form-item>
      <upload-form-item ref="avatarUrl"  @parentEvent="getAvatarUrl"></upload-form-item>
      <el-button @click="submit()" id="layuiadmin-app-form-submit" style="display: none">提交</el-button>
    </el-form>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import {request, TipEnum, UrlEnum} from "../../../public/js/common-vue";
import UploadFormItem from "@/components/UploadFormItem";

export default {
  name: "AdSave",
  data() {
    return {
      loading: false,
      loadingText:"",
      id:'',
      teacher:{
        name:'',
        username:'',
        avatarUrl:''
      },
    }
  },
  components: {UploadFormItem, Loading},
  methods:{
    handleChange(id){
      this.setPicSize(id);
    },
    getAvatarUrl(data){
      this.teacher.avatarUrl = data;
    },
    submit(){
      if(this.teacher.name == ""){
        this.showMsgError("请输入讲师姓名");
        return;
      }
      if(this.teacher.username == ""){
        this.showMsgError("请输入手机号");
        return;
      }
      if(this.teacher.avatarUrl == ""){
        this.showMsgError("请上传讲师图片");
        return;
      }
      let url = UrlEnum.TEACHER;
      let method = "post";
      if(this.id != 0){
        url += "/"+this.id;
        method = "put";
      }

      this.loadingText="提交中";
      this.loading = true;
      this.$http({
        method: method,
        url: url,
        data: this.teacher,
      })
      .then((res) => {
        this.loading = false;
        this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
        setTimeout(function () {
          window.parent.postMessage({
            msg: '',
            key: 'refresh'
          }, '*')
        }, 800);
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    initData(){
      this.loadingText="加载中";
      this.loading=true;
      const url = UrlEnum.TEACHER + "/" + this.id
      this.$http({
        method: "get",
        url: url,
        data: '',
      })
      .then((res) => {
        this.loading=false;
        this.teacher = res.data;
        this.$refs.avatarUrl.init("讲师图片",true,'',this.teacher.avatarUrl);
      })
      .catch((res) => {
        this.loading = false;
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      })
    }
  },
  mounted() {
    if(request('id') != null && request('id') != ''){
      this.id = request('id');
      this.initData();
    }
    else{
      this.$refs.avatarUrl.init("讲师图片",true,'400 x 400',this.teacher.avatarUrl);
    }
  }
}
</script>

<style>
@import "../../../static/css/add.css";
@import "../../../static/css/editor.css";
</style>
